import * as _plugin2 from "tailwindcss/plugin";
var _plugin = "default" in _plugin2 ? _plugin2.default : _plugin2;
import _delay from "./utilities/delay";
import _direction from "./utilities/direction";
import _duration from "./utilities/duration";
import _fillMode from "./utilities/fillMode";
import _iterationCount from "./utilities/iterationCount";
import _playState from "./utilities/playState";
import _timingFunction from "./utilities/timingFunction";
import _theme from "./theme";
var exports = {};
const plugin = _plugin;
exports = plugin(api => {
  _delay(api);
  _direction(api);
  _duration(api);
  _fillMode(api);
  _iterationCount(api);
  _playState(api);
  _timingFunction(api);
}, {
  theme: _theme
});
export default exports;